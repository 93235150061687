import React from "react";
import Lottie from "lottie-react";


const LottieAnimation = ({ animation }) => {
    return (
        <div className="my-horizontal-container m-5" style={{ backgroundColor: "white" }}>
            <div style={{ marginTop: '100px' }}>
                <p style={{ fontSize: "45px", fontWeight: "500", color: 'black', fontStyle: "italic" }}>Animation</p>
            </div>
            <Lottie animationData={animation} loop={true} style={{ height: "350px" }} />;
        </div>
    );
};

export default LottieAnimation;