import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { revokeSession } from '../Http/api';
import { CloseOutlined, DownOutlined, HomeOutlined, ProjectTwoTone, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Dropdown, Space, Tooltip } from 'antd';
import ResizableDrawer from './Antd/ResizableDrawer';
import { AgGridReact } from 'ag-grid-react';

const Header = () => {
    const [project, setPoject] = useState([
        { taskName: 'Project 1', },
        { taskName: 'Project 2', },
        { taskName: 'Project 3', },
        { taskName: 'Project 4', },
        { taskName: 'Project 5', },

    ]);

    const columnDefs = [
        {
            headerName: "S.no",
            valueGetter: "node.rowIndex + 1",
            sortable: false,
            filter: false,
            width: 60
        },
        {
            headerName: "Task Name", field: "task_name", flex: true, cellStyle: params => {
                return {
                    color: '#1677ff',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    fontWeight: '500'
                };
            }
        },
    ];

    const rowData = project.map((user, index) => ({
        'task_name': `${user.taskName}`
    }));

    const [menuDrawer, setMenuDrawer] = useState(false)
    const [projectDrawer, setProjectDrawer] = useState(false)
    const [drawerWidth, setDrawerWidth] = useState(0);

    const items = [
        {
            key: '1',
            label: (
                <div onClick={() => LogOut()}>
                    <span>Logout</span>
                </div>
            ),
            icon: <i onClick={() => LogOut()} className="fa fa-sign-out" style={{ fontSize: "20px", color: "black", cursor: "pointer" }} aria-hidden="true" />,
        }
    ];
    const [userData, setUserData] = useState(null)
    const navigate = useNavigate();
    const headerStyle = {
        backgroundColor: 'black',
        color: 'white',
        height: "6vh",
    };
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userData'));
        if (user) {
            setUserData(user)
        }
    }, [])
    const LogOut = async () => {
        const user = JSON.parse(localStorage.getItem('userData'))
        if (user) {
            const sessionId = user.createdSessionId;
            revokeSession(sessionId).then((response) => {
                console.log(response?.success, 'resp')
                if (response.success) {
                    localStorage.removeItem('userData')
                    navigate('/')
                }
            });
        }
    }

    const handleFunction = (width) => {
        setDrawerWidth(width);
    };


    return (
        <header style={headerStyle} >
            <ResizableDrawer
                closeIcon={false}
                defaultWidth="3%"
                maxWidthSize="3%"
                placement={'left'}
                handleFunction={handleFunction}
                title={<span></span>}
                onClose={() => {
                    setMenuDrawer(false);
                }}
                open={menuDrawer}
                extra={
                    <div onClick={() => setMenuDrawer(false)} style={{ position: 'absolute', right: '20px', top: '15px' }}>
                        <div className="">
                            <HomeOutlined className='menuIcon' />
                        </div>
                    </div>
                }
                backgroundColor={"black"}
            >
                <div className='mt-10'>
                    <Tooltip title="Procject Access" placement="rightTop" overlayClassName="custom-tooltip" color='blue'>
                        <ProjectTwoTone className='menuIcon' onClick={() => setProjectDrawer(true)} />
                    </Tooltip>
                </div>
                <div className='mt-4'>
                    <Tooltip title="Users" placement="rightTop" overlayClassName="custom-tooltip">
                        <UserOutlined className='menuIcon' />
                    </Tooltip>
                </div>
            </ResizableDrawer>
            <ResizableDrawer
                closeIcon={false}
                defaultWidth="50%"
                maxWidthSize="30%"
                placement={'right'}
                handleFunction={handleFunction}
                title={<span>Project List</span>}
                onClose={() => {
                    setProjectDrawer(false);
                }}
                open={projectDrawer}
                extra={
                    <div onClick={() => setProjectDrawer(false)} style={{ position: 'absolute', right: '20px', top: '15px' }}>
                        <div className="">
                            <HomeOutlined className='menuIcon' />
                        </div>
                    </div>
                }
            >
                <div className="ag-theme-quartz" style={{ height: '850px' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                    />
                </div>
            </ResizableDrawer>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                <div onClick={() => { setMenuDrawer(true) }} >
                    <HomeOutlined style={{ fontSize: '150%', cursor: "pointer" }} />
                </div>
                <div style={{ width: "20%" }}>
                    <Input size='medium' placeholder="Search..." prefix={<SearchOutlined />} />
                </div>
                <div >
                    <Dropdown
                        menu={{ items }}
                        overlayStyle={{ width: '150px' }} // Increase dropdown width here
                    >
                        <Space>
                            {userData?.identifier}
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
};

export default Header;