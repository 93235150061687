import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

const countriesData = [
  {
    icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/flags/india.svg',
    heading: "India",
    text: "Direct link clicks",
    count: "9,762"
  },
  {
    icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/flags/united-states.svg',
    heading: "United States",
    text: "Direct link clicks",
    count: "9,762"
  },
  {
    icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/flags/brazil.svg',
    heading: "Brazil",
    text: "Direct link clicks",
    count: "9,762"
  },
  {
    icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/flags/turkey.svg',
    heading: "Turkey",
    text: "Direct link clicks",
    count: "562"
  }
  ,
  {
    icon: '	https://preview.keenthemes.com/keen/demo1/assets/media/flags/france.svg',
    heading: "France",
    text: "Direct link clicks",
    count: "762"
  },
  {
    icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/flags/sweden.svg',
    heading: "Swenden",
    text: "Direct link clicks",
    count: "662"
  },
  {
    icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/flags/united-states.svg',
    heading: "United States",
    text: "Direct link clicks",
    count: "9,862"
  },
  {
    icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/flags/brazil.svg',
    heading: "Brazil",
    text: "Direct link clicks",
    count: "9,762"
  },
];
const CountryVisitsCard = () => {
  const [countryData, setCountryData] = useState([])
  useEffect(() => {
    setCountryData(countriesData)
  }, [])
  return (
    <div className="my-tasks-container ml-5">
      <div className='head'>
        <div className='head-text'>
          <h2 style={{ fontWeight: 'bold' }}>Visit By Country</h2>
          <p style={{ color: 'lightgray', fontSize: '15px' }}>20 countries share 97% visits</p>
        </div>
        <div className='m-4' >
          <Button>View All</Button>
        </div>
      </div>
      <div className="tasks-body">
        {
          countryData.map((country, index) => {
            return (
              <div className="task-row">
                <div style={{ display: 'flex' }}>
                  <img src={country.icon} class="me-4 w-25px" style={{ borderRadius: '10px' }} alt="" />
                  <div>
                    <p className='text-gray-800 text-hover-primary fs-6'>{country.heading}</p>
                    <a href="#" style={{ color: 'lightgray', fontSize: '14px' }}>{country.text}</a>
                  </div>
                </div>
                <div>
                  <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{country.count}</p>
                </div>
              </div>
            )
          })
        }
        {/* <div className="task-row">
          <div style={{ display: 'flex' }}>
            <img src="https://preview.keenthemes.com/keen/demo1/assets/media/flags/united-states.svg" class="me-4 w-25px" style={{ borderRadius: '10px' }} alt="" />
            <div>
              <p className='text-gray-800 fw-bold text-hover-primary fs-6'>United States</p>
              <a href="#" style={{ color: 'lightgray', fontSize: '15px' }}>Direct link clicks</a>
            </div>
          </div>
          <div>
            <p style={{ fontWeight: 'bold' }}>9,763</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CountryVisitsCard;