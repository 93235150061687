import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';

const VerticalTiles = ({ text, animation  }) => {

    return (
        <div className="my-tasks-container ml-5 mb-5" style={{ height: "600px" }}>
            <div style={{ textAlign: 'center', marginTop: '100px' }}>
                <p style={{ fontSize: "30px", fontWeight: "500" }}>Have you tried</p>
                <p style={{ fontSize: "30px", fontWeight: "500" }}>new <span style={{ fontWeight: "700" }}>customer portal ?</span></p>
            </div>
            <div style={{ padding: '30px'}}>
                <Lottie animationData={animation} loop={true} style={{ height: "350px", padding: "10px" }} />;
            </div>
        </div>
    );
};

export default VerticalTiles;