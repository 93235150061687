import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        // .min(8, 'Password must be at least 8 characters')
        // .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        // .matches(/[0-9]/, 'Password must contain at least one number')
        // .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
});

export default loginSchema;