import React from 'react';
import { Navigate } from 'react-router-dom';

const checkAUth = () => {
  const data = localStorage.getItem('userData');
  return data ? true : false;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return checkAUth() ? <Component {...rest} /> : <Navigate to="/" />;
};

export default PrivateRoute;