import React, { useEffect, useState } from 'react';
const HorizontalCard = () => {
    return (
        <div className="my-horizontal-container m-5">
            <div style={{ marginTop: '100px' }}>
                <p style={{ fontSize: "55px", fontWeight: "500", color: 'white', letterSpacing:"1px" }}>Are you verified ?</p>
            </div>
            <div style={{ padding: '30px' }}>
                <img
                    src='https://preview.keenthemes.com/html/keen/docs/assets/media/illustrations/sigma-1/17.png'
                    style={{ height: '100%' }}
                />
            </div>
        </div>
    );
};

export default HorizontalCard;