import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faGraduationCap, faTachometerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import illustration from '../assets/images/1.png'; // Import the image

const MyTasks = () => {
    return (
        <div className="my-tasks-container ml-5">
            <div className="tasks-header">
                <img src={illustration}  alt="Illustration" />
            </div>
            <div className="tasks-grid">
                <div className="task-card">
                    <FontAwesomeIcon icon={faFlask} className="icon" />
                    <h3>37</h3>
                    <p>Open Task</p>
                </div>
                <div className="task-card">
                    <FontAwesomeIcon icon={faGraduationCap} className="icon" />
                    <h3>6</h3>
                    <p>Panding Task</p>
                </div>
                <div className="task-card">
                    <FontAwesomeIcon icon={faTachometerAlt} className="icon" />
                    <h3>4</h3>
                    <p>Closed Task</p>
                </div>
                <div className="task-card">
                    <FontAwesomeIcon icon={faClock} className="icon" />
                    <h3>8</h3>
                    <p>Completed Task</p>
                </div>
            </div>
        </div>
    );
}

export default MyTasks;