import { Card } from 'antd';
import { useEffect, useState } from 'react';
import MyTasks from './MyTasks';
import CountryVisitsCard from './Tiles/CountryVisitsCard';
import SocialNextworkCard from './Tiles/SocialNextworkCard';
import CustomerCard from './Tiles/CustomerCard';
import HorizontalCard from './Tiles/HorizontalCard';
import HorizontalTiles from './Tiles/HorizontalTiles';
import DashboardTiles from './Tiles/DashboardTiles';
import LottieAnimation from './Lottie';
import VerticalTiles from './Tiles/VerticalTiles';
import workAnimation from '../assets/lottie/work-animation.json'
import taskAnimation from '../assets/lottie/task-animation.json'
import animationOne from '../assets/lottie/Animation-1726834950131.json'
import animationTwo from '../assets/lottie/Animation-1726837594939.json'
import animationThree from '../assets/lottie/Animation-1726835091722.json'
import animationFour from '../assets/lottie/Animation-1726835134005.json'
import animationFive from '../assets/lottie/Animation-1726835179169.json'
const Tiles = ({ TasksListDrawer, setTasksListDrawer }) => {
    const [Tiles, setTiles] = useState([])
    useEffect(() => {
        setTiles([
            { name: 'Open Task', value: 10 },
            { name: 'End Task', value: 10 },
            { name: 'In Progress Task', value: 10 },
        ])
    }, [])
    return (
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '70%', padding: '0px' }}>
                {
                    Tiles.map((tile, index) => {
                        return (
                            <Card className='tiles' onClick={() => setTasksListDrawer(true)}>
                                <p>{tile.name}</p>
                                <p>{tile.value}</p>
                            </Card>
                        )
                    })
                }

            </div>
            <div style={{ marginTop: "10px", display: 'flex', flexDirection: 'row' }}>
                <DashboardTiles width="49%" text="What's New In Dashboard ?" image='https://preview.keenthemes.com/html/keen/docs/assets/media/illustrations/unitedpalms-1/19.png' />
                <DashboardTiles width="49%" text="Connecting You To The New Solutions!" image='https://preview.keenthemes.com/html/keen/docs/assets/media/illustrations/dozzy-1/4.png' />
            </div>
            <div style={{ marginTop: "10px", display: 'flex', flexDirection: 'row' }}>
                <MyTasks />
                <CountryVisitsCard />
                <SocialNextworkCard />
                <CustomerCard />
            </div>
            <div style={{ marginTop: "10px", display: 'flex', flexDirection: 'row' }}>
                <HorizontalCard />
                <HorizontalTiles />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <LottieAnimation animation={workAnimation} />
                <LottieAnimation animation={taskAnimation} />
            </div>
            <div style={{ marginTop: "10px", display: 'flex', flexDirection: 'row' }}>
                <VerticalTiles text={'Dummy Text'} animation={animationOne} />
                <VerticalTiles text={'Dummy Text'} animation={animationTwo}/>
                <VerticalTiles text={'Dummy Text'} animation={animationThree} />
                <VerticalTiles text={'Dummy Text'} animation={animationFour} />
                <VerticalTiles text={'Dummy Text'} animation={animationFive} />
            </div>
        </div>
    )
}

export default Tiles