import React, { useEffect } from 'react';
import { Formik, Field } from "formik";
import { useNavigate } from "react-router-dom";
import loginSchema from '../Validations/loginSchema.js';
import { clerk, loadClerk } from '../Auth/Clark.js';
const Login = () => {
    const navigate = useNavigate();
    const intialValues = {
        email: "",
        password: "",
    }

    const signOutUser = async () => {
        await clerk.signOut()
    }
    const signIn = async (values) => {
        try {
            const authData = {
                identifier: values.email,
                password: values.password
            }

            const signInAttempt = await clerk.client.signIn.create(authData)
            localStorage.setItem('userData', JSON.stringify(signInAttempt))
            navigate("/dashboard")
        } catch (error) {
            console.log(error)
            alert('Invalid Credentials')
        }

    }
    useEffect(() => {
        loadClerk()
        signOutUser()
    })
    const onSubmit = async (values) => {
        signIn(values)
    }
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8" style={{ height: "90vh" }} >
            <div className="flex justify-center flex-col items-center">
                <h2 className="mt-6 text-center text-2xl leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <Formik
                        initialValues={intialValues}
                        validationSchema={loginSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, handleSubmit, handleChange, touched, errors }) => (
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <Field
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <Field
                                            id="password"
                                            name="password"
                                            type="text"
                                            autoComplete="current-password"
                                            className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full border border-black justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-white transition-colors hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default Login;