import React, { useState, useEffect } from "react";
import { Drawer, Tabs } from "antd";

let isResizing = null;
const { TabPane } = Tabs;
const ResizableDrawer = ({
  children,
  rootExtraClassName = "",
  defaultWidth = "20%",
  minWidthSize = "15%",
  maxWidthSize = "50%",
  placement,
  key,
  tabs,
  handleFunction,
  activeTabKey,
  setActiveTabKey,
  backgroundColor,
  ...props
}) => {
  const [drawerWidth, setDrawerWidth] = useState(defaultWidth);

  const cbHandleMouseMove = React.useCallback(handleMousemove, []);
  const cbHandleMouseMoveLeft = React.useCallback(handleMousemoveleft, []);
  const cbHandleMouseUp = React.useCallback(handleMouseup, []);

  // useEffect(() => {
  //   setDrawerWidth(props.width);
  // }, [props.visible]);
  useEffect(() => {
    if (handleFunction) {
      handleFunction(defaultWidth);
    }
  }, [defaultWidth]);

  function handleMouseup(e) {
    if (!isResizing) {
      return;
    }
    isResizing = false;
    document.removeEventListener("mousemove", cbHandleMouseMove);
    document.removeEventListener("mousemove", cbHandleMouseMoveLeft);
    document.removeEventListener("mouseup", cbHandleMouseUp);
    document.querySelector(".resizableDrawer").classList.remove("resizing");
  }
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  function handleMousedown(e) {
    e.stopPropagation();
    e.preventDefault();
    // we will only add listeners when needed, and remove them afterward
    document.addEventListener("mousemove", cbHandleMouseMove);
    document.addEventListener("mousemove", cbHandleMouseMoveLeft);
    document.addEventListener("mouseup", cbHandleMouseUp);
    document.querySelector(".resizableDrawer").classList.add("resizing");
    isResizing = true;
  }

  function handleMousemove(e) {
    const offsetRight =
      document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    const screenWidth = window.screen.width;
    const offsetRightPercentage = `${pad(
      ((offsetRight / screenWidth) * 100).toFixed(2)
    )}%`;
    if (
      offsetRightPercentage > minWidthSize &&
      offsetRightPercentage < maxWidthSize
    ) {
      setDrawerWidth(offsetRightPercentage);
      handleFunction(offsetRightPercentage);
    }
  }
  function handleMousemoveleft(e) {
    if (placement === "left") {
      const offsetLeft = e.clientX - document.body.offsetLeft;
      const screenWidth = window.screen.width;
      const offsetLeftPercentage = `${pad(
        ((offsetLeft / screenWidth) * 100).toFixed(2)
      )}%`;

      if (
        offsetLeftPercentage > minWidthSize &&
        offsetLeftPercentage < maxWidthSize
      ) {
        setDrawerWidth(offsetLeftPercentage);
        handleFunction(offsetLeftPercentage);
      }
    }
  }

  return (
    <Drawer
      style={{ backgroundColor: backgroundColor }} // Change background color here

      rootClassName={`resizableDrawer ${rootExtraClassName}`}
      {...props}
      placement={placement}
      width={drawerWidth}
      key={key}
    >
      {/* {tabs && (
        <div className="drawer-tabs">
          <Tabs defaultActiveKey="1" onChange={(key) => setActiveTabKey(key)} >
            {tabs &&
              tabs.map((tab, index) => (
                <TabPane tab={tab.title} key={index}>
                  {tab.content}
                </TabPane>
              ))}
          </Tabs>
        </div>
      )} */}
      {
        backgroundColor ? <></> : <div className="sidebar-dragger" onMouseDown={handleMousedown} />
      }

      {children}
    </Drawer>
  );
};

export default ResizableDrawer;
