import { Routes, Route } from "react-router-dom"
import Dashboard from "../Pages/Dashboard.js"
import PrivateRoute from "../Auth/PrivateRoute.js"
import Login from "../Pages/Login.js"
// {< PrivateRoute component={Dashboard} />}
export default function RouteList() {
    return (
        <Routes>
            <Route
                path="/"
                element={<Login />}
            />
            <Route
                path="/dashboard"
                element= {< PrivateRoute component={Dashboard} />}
            />
            <Route path="*" element={<><h1>Page Not Found</h1></>} />
        </Routes>
    )
}
