import { apiRequest } from "./apiService"
import { apiRequestDataGrid } from "./apiServiceDataGrid";
export const userAuth = async (userData) => {
    try {
        const response = await apiRequest("post", "/authOkta", userData);
        console.log('test')
        return response;
    } catch (error) {
        console.log('catch', error)
        return error;
    }
}

export const getAllUser = async () => {
    try {
        const response = await apiRequest("get", "/getUsers");
        return response;
    } catch (error) {
        return false;
    }
}

export const createUser = async (userData) => {
    // const data = {
    //     "email": userData.email,
    //     "given_name": userData.first_name,
    //     "family_name": userData.last_name,
    //     "password": userData.password
    // }
    try {
        const response = await apiRequest("post", "/createUser", userData);
        return response;
    } catch (error) {
        return false;
    }
}

export const getUserDetails = async (userId) => {
    try {
        const response = await apiRequest("get", `/users/${userId}`);
        return response;
    } catch (error) {
        return false;
    }
}

export const eventList = async (id) => {
    try {
        const response = await apiRequest("get", `/autho-events/${id}`);
        return response;
    } catch (error) {
        return false;
    }
}

export const getGrantsByUser = async (id) => {
    try {
        const response = await apiRequest("get", `/grants/user/${id}`);
        return response;
    } catch (error) {
        return false;
    }
}

export const updatePassowrd = async (id, updatedPassword) => {
    try {
        const response = await apiRequest("patch", `/update-password/${id}`, updatedPassword);
        return response;
    } catch (error) {
        return false;
    }
}

export const getIPData = async (id) => {
    try {
        const response = await apiRequest("get", `/ip-list/${id}`);
        return response;
    } catch (error) {
        return false;
    }
}

export const addIPData = async (data) => {
    try {
        const response = await apiRequest("post", `/ip-add`, data);
        return response;
    } catch (error) {
        return false;
    }
}

export const getEmployees = async () => {
    try {
        const response = await apiRequestDataGrid("POST", "https://apicr3-main.vercel.app/api/records/list/?t=trgdhsx", { "external_": true });
        return response;
    } catch (error) {
        return false;
    }
}

export const getOrganizations = async () => {
    try {
        const response = await apiRequest("GET", "/getOrganizations");
        return response;
    } catch (error) {
        return false;
    }
}

export const revokeSession = async (sessionId) => {
    try {
        const response = await apiRequest("GET", `/revokeSession/${sessionId}`);
        return response;
    } catch (error) {
        return false;
    }
}