// src/clerkSetup.js
import { Clerk } from '@clerk/clerk-js';
// Replace with your Clerk frontend API key
// App1 :'pk_test_bmV3LW1vbnN0ZXItNjIuY2xlcmsuYWNjb3VudHMuZGV2JA'
// ClearkAuth : 'pk_test_a25vd2luZy1hbGJhY29yZS0yMy5jbGVyay5hY2NvdW50cy5kZXYk
const clerkFrontendApi = 'pk_test_a25vd2luZy1hbGJhY29yZS0yMy5jbGVyay5hY2NvdW50cy5kZXYk';
const clerk = new Clerk(clerkFrontendApi);

const loadClerk = async () => {
  try {
    await clerk.load();
    console.log('Clerk loaded successfully');
  } catch (error) {
    console.error('Error loading Clerk:', error);
  }
};

export { clerk, loadClerk };