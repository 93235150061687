import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import Tiles from "../Components/Tiles";
import { CloseOutlined } from "@ant-design/icons";
import ResizableDrawer from "../Components/Antd/ResizableDrawer";
const Dashboard = () => {
    const [users, setUserData] = useState([
        { taskName: 'Test Task 1', },
        { taskName: 'Test Task 2', },
        { taskName: 'Test Task 3', },
        { taskName: 'Test Task 4', },
        { taskName: 'Test Task 5', },

    ]);
    const [TasksListDrawer, setTasksListDrawer] = useState(false);
    const [TaskDetailsDrawer, setTaskDetailsDrawer] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(0);

    const columnDefs = [
        {
            headerName: "S.no",
            valueGetter: "node.rowIndex + 1",
            sortable: false,
            filter: false,
            width: 60
        },
        {
            headerName: "Task Name", field: "task_name", flex: true, cellStyle: params => {
                return {
                    color: '#1677ff',
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    fontWeight: '500'
                };
            }
        },
    ];

    const rowData = users.map((user, index) => ({
        'task_name': `${user.taskName}`
    }));


    const handleFunction = (width) => {
        setDrawerWidth(width);
    };

    const onRowClicked = (params) => {
        setTaskDetailsDrawer(true);
    };

    useEffect(() => {
        
    }, [])

    return (
        <>
            <Header />
            <Tiles TasksListDrawer={TasksListDrawer} setTasksListDrawer={setTasksListDrawer} />
            <ResizableDrawer
                closeIcon={false}
                defaultWidth="50%"
                maxWidthSize="50%"
                placement={'left'}
                handleFunction={handleFunction}
                title={<span>Task List</span>}
                onClose={() => {
                    setTasksListDrawer(false);
                }}
                open={TasksListDrawer}
                extra={
                    <div onClick={() => setTasksListDrawer(false)} style={{ position: 'absolute', right: '15px', top: '2px' }}>
                        <div className="closeBox">
                            <CloseOutlined style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                }
            >
                <div className="ag-theme-quartz" style={{ height: '850px' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onRowClicked={onRowClicked}
                    />
                </div>
            </ResizableDrawer>
            <ResizableDrawer
                closeIcon={false}
                defaultWidth="50%"
                maxWidthSize="50%"
                placement={'right'}
                handleFunction={handleFunction}
                title={<span>Task List</span>}
                onClose={() => {
                    setTaskDetailsDrawer(false);
                }}
                open={TaskDetailsDrawer}
                extra={
                    <div onClick={() => setTaskDetailsDrawer(false)} style={{ position: 'absolute', right: '15px', top: '2px' }}>
                        <div className="closeBox">
                            <CloseOutlined style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                }
            >
                <div className="ag-theme-quartz" style={{ height: '850px' }}>
                    <p>Task Details Here</p>
                </div>
            </ResizableDrawer>
        </>
    );
};

export default Dashboard;