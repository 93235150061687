import React, { useEffect, useState } from 'react';
const HorizontalTiles = () => {
    return (
        <div className="my-horizontal-container m-5">
            <div style={{ marginTop: '100px' }}>
                <p style={{ fontSize: "40px", fontWeight: "500", color: 'white' }}>Feel free to use them in your lessons.</p>
            </div>
            <div style={{ padding: '20px 0px' }}>
                <img
                    src='https://www.ibexa.co/var/site/storage/images/_aliases/ibexa_block/8/4/1/6/226148-6-eng-GB/Customer%20Portal_Features.png'
                    style={{ height: '100%' }}
                />
            </div>
        </div>
    );
};

export default HorizontalTiles;