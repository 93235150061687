import React, { useEffect, useState } from 'react';
const CustomerCard = () => {

    return (
        <div className="my-tasks-container ml-5">
            <div style={{textAlign:'center', marginTop:'100px'}}>
                <p style={{fontSize:"30px", fontWeight:"500"}}>Have you tried</p>
                <p style={{fontSize:"30px", fontWeight:"500"}}>new <span style={{fontWeight:"700"}}>customer portal ?</span></p>
            </div>
            <div style={{padding:'30px'}}>
                <img
                    src='https://www.ibexa.co/var/site/storage/images/_aliases/ibexa_block/4/4/1/6/226144-5-eng-GB/Customer%20Portal-Illustration.png'
                />
            </div>
        </div>
    );
};

export default CustomerCard;