import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

const SocialData = [
    {
        icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/svg/brand-logos/dribbble-icon-1.svg',
        heading: "Dribbble",
        text: "Community",
        count: "762"
    },
    {
        icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/svg/brand-logos/linkedin-1.svg',
        heading: "Linked In",
        text: "Social Media",
        count: "1,762"
    },
    {
        icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/svg/brand-logos/slack-icon.svg',
        heading: "Slack",
        text: "Messanger",
        count: "662"
    },
    {
        icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/svg/brand-logos/youtube-3.svg',
        heading: "Youtube",
        text: "Video Channel",
        count: "1662"
    },
    {
        icon: 'https://preview.keenthemes.com/keen/demo1/assets/media/svg/brand-logos/instagram-2-1.svg',
        heading: "Instagram",
        text: "Social Network",
        count: "5662"
    },
];
const SocialNextworkCard = () => {
    const [socialData, setSocialData] = useState([])
    useEffect(() => {
        setSocialData(SocialData)
    }, [])
    return (
        <div className="my-tasks-container ml-5">
            <div className='head'>
                <div className='head-text'>
                    <h2 style={{ fontWeight: 'bold', fontSize: '40px' }}>5,037</h2>
                    <p style={{ color: 'lightgray', fontSize: '15px' }}>Visits by Social Networks</p>
                </div>
                <div className='m-4' >
                    <Button>View All</Button>
                </div>
            </div>
            <div className="tasks-body">
                {
                    SocialData.map((country, index) => {
                        return (
                            <div className="task-row">
                                <div style={{ display: 'flex' }}>
                                    <img src={country.icon} class="me-4 w-25px" style={{ borderRadius: '10px' }} alt="" />
                                    <div>
                                        <p className='text-gray-800 text-hover-primary fs-6'>{country.heading}</p>
                                        <a href="#" style={{ color: 'lightgray', fontSize: '14px' }}>{country.text}</a>
                                    </div>
                                </div>
                                <div>
                                    <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{country.count}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default SocialNextworkCard;