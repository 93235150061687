import React, { useEffect, useState } from 'react';
const DashboardTiles = ({text,image, width}) => {
    return (
        <div className="my-horizontal-container m-1" style={{width:width, backgroundColor:"white"}}>
            <div style={{ marginTop: '100px' }}>
                <p style={{ fontSize: "55px", fontWeight: "500", color: 'white', letterSpacing:"1px", fontStyle:"italic", color:"rgb(23, 56, 99)" }}>{text}</p>
            </div>
            <div style={{ padding: '30px' }}>
                <img
                    src={image}
                    style={{ height: '100%', width: '600px' }}
                />
            </div>
        </div>
    );
};

export default DashboardTiles;